import React from 'react'
import "./Loader.css"

const Loader = () => {
  return (
    <div className="loader_whole_component">

    <div className="loader"></div>
    </div>
  )
}

export default Loader
